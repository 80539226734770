:root {
  --image-url: url('https://via.placeholder.com/200')
}

main {
  min-height: 100vh;
  display: grid;
  grid:
    "header" auto
    "main" minmax(0,1fr)
    "footer" auto;
}

header {
  grid-area: header;
}

#content-container {
  grid-area: main;
  align-self: stretch
}

footer {
  grid-area: footer;
}


.slide-text {
  opacity: 0;
}
.splide__slide.is-active .slide-text:nth-child(1) {
  opacity: 0;
  animation: showSlideDetail .5s .1s ease-in-out 1 forwards  ;
}

.splide__slide.is-active .slide-text:nth-child(2) {
  opacity: 0;
  animation: showSlideDetail .5s .2s ease-in-out 1 forwards  ;
}

.splide__slide.is-active .slide-text:nth-child(3) {
  opacity: 0;
  animation: showSlideDetail .5s .3s ease-in-out 1 forwards  ;
}

@keyframes showSlideDetail {
  from{
    transform: translateY(50px);
  }
  to{
    opacity: 1;
    transform: translateY(0);
  }
}


#about-us svg {
  max-width: 600px;
  overflow: visible;
}


#about-us .ball {
  fill: #111827;
  visibility: hidden;
}

#about-us .line {
  fill: none;
  stroke: #111827;
  stroke-width: 2px;
}
#about-us text {
  fill: #111827;
  font-size: 15px;
  font-family: "Signika Negative", sans-serif; 
  visibility: hidden;
}

